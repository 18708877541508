* {
  margin: 0;
  padding: 0;
}

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  font-family: sans-serif;
  list-style-type: none;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  transition: all 0.5s;
  backface-visibility: hidden;
  will-change: transform;
  transition: all 0.5s;
}
.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
}
.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transition: all 0.5s;
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/
.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.hex h1,
.hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  font-weight: 300;
  opacity: 0;
}

#demo1 {
  color: #f5ce95;
  text-transform: capitalize;
  text-align: center;
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
}
.hex h1:before,
.hex h1:after {
  display: inline-block;
  margin: 0 0.5em;
  width: 0.25em;
  height: 0.03em;
  background: #ffffff;
  content: '';
  vertical-align: middle;
  transition: all 0.3s;
  text-align: center;
}

#demo2 {
  top: 50%;
  text-align: center;
  text-transform: uppercase;
}

.img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: background-image 0.5s ease-in;
  transform-style: preserve-3d;
}

.halfimg1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 50% 75%, 50% 25%);
}

.halfimg2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 50% 25%, 50% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.halfimg3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  -webkit-clip-path: polygon(
    0% 0%,
    /* Top-left corner */ 100% 0%,
    /* Top-right corner */ 100% 35%,
    /* Bottom-right corner */ 0% 35% /* Bottom-left corner */
  );
  clip-path: polygon(
    0% 0%,
    /* Top-left corner */ 100% 0%,
    /* Top-right corner */ 100% 35%,
    /* Bottom-right corner */ 0% 35% /* Bottom-left corner */
  );
}

.whyus {
  position: absolute;
  margin-top: 5%;
  text-align: center;
  width: 65%;
}

.whyus_title h1 {
  font-size: 4rem;
  font-weight: bolder;
  opacity: 0.1;
}

.whyus_content {
  position: absolute;
  margin-top: -2.3rem;
  margin-left: 28%;
}

.whyus_content h4 {
  font-size: 2.18rem;
  font-weight: bolder;
  white-space: nowrap;
}
.whyus_content p {
  font-size: 15px;
  margin-left: 1rem;
  margin-top: 0;
}

/*** HOVER EFFECT  **********************************************************************/

.hexLink:hover h1,
.hexLink:focus h1,
.hexLink:hover p,
.hexLink:focus p {
  opacity: 1;
  transition: 0.8s;
}

.hexIn:hover .img:before,
.hexIn:hover .img:after,
.hexIn:hover .hexLink {
  opacity: 1;
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 800px) {
  /* <- 5-4  hexagons per row */
  /* #hexGrid {
    padding-bottom: 4.4%;
  } */
  .hex {
    width: 20%; /* = 100 / 5 */
  }
  .hex:nth-child(10n + 1) {
    /* first hexagon of even rows */
    margin-left: 10%; /* = width of .hex / 2  to indent even rows */
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }
  .hex {
    width: 20%; /* = 100 / 5 */
  }
  .hex:nth-child(10n + 1) {
    /* first hexagon of even rows */
    margin-left: 10%; /* = width of .hex / 2  to indent even rows */
  }
  .whyus_title h1 {
    font-size: 2rem;
  }
  .whyus_content {
    display: none;
  }
}
