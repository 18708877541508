.landingg {
  align-items: left;
  justify-content: left;
  margin-top: 8rem;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.status-card {
  background-color: #f5f5f5;
  padding: 20px 20px 20px;
  margin: 5px;
  opacity: 1;
  border: 1px solid #e6e6e6;
}

.row {
  width: 100%;
  text-align: center;
}

.col-md-4 {
  padding: 0;
}
.data {
  display: block;
  text-align: left;
}

.key {
  color: #808080;
  font-weight: bold;
}

.value {
  color: black;
  font-weight: 700;
  font-size: 16px;
}

.message {
  display: block;
  font-weight: 400;
  font-size: 16px;
}

.status {
  align-items: left;
  justify-content: left;
  text-align: left;
  margin: 10px 10px;
}

.btn-warning {
  width: 10rem;
  padding-top: 0;
  padding-bottom: 0;
}
