.AllProjects {
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 3%;
}
.ProjectsGridIntro {
  font-size: 16px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
}
.fa-solid.fa-sliders {
  color: #808080;
}
.Filter {
  float: right;
}
.grid {
  display: grid;
  grid-template-columns: repeat(10, auto);
  grid-template-rows: repeat(34, auto);
  width: 95%;
  min-height: 100rem;
  grid-gap: 15px;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
}

.grid-item1 {
  background-image: url('../../../../public/Images/GridImages/image0.png');
  background-size: cover;
  background-position: center;
  grid-column: 1/6;
  grid-row: 1/12;
  cursor: pointer;
}

.grid-item2 {
  background-image: url('../../../../public/Images/GridImages/image1.png');
  background-size: cover;
  background-position: center;
  grid-column: 6/9;
  grid-row: 1/8;
  cursor: pointer;
}

.grid-item3 {
  background-image: url('../../../../public/Images/GridImages/image2.png');
  background-size: cover;
  background-position: center;
  grid-column: 9/11;
  grid-row: 1/9;
  cursor: pointer;
}

.grid-item4 {
  background-image: url('../../../../public/Images/GridImages/image3.png');
  background-size: cover;
  background-position: center;
  grid-column: 1/4;
  grid-row: 12/22;
  cursor: pointer;
}

.grid-item5 {
  background-image: url('../../../../public/Images/GridImages/image4.png');
  background-size: cover;
  background-position: center;
  grid-column: 4/7;
  grid-row: 12/22;
  cursor: pointer;
}

.grid-item6 {
  background-image: url('../../../../public/Images/GridImages/image5.png');
  background-size: cover;
  background-position: center;
  grid-column: 7/11;
  grid-row: 9/18;
  cursor: pointer;
}

.grid-item7 {
  background-image: url('../../../../public/Images/GridImages/image6.png');
  background-size: cover;
  background-position: center;
  grid-column: 1/3;
  grid-row: 22/31;
  cursor: pointer;
}

.grid-item8 {
  background-image: url('../../../../public/Images/GridImages/image7.png');
  background-size: cover;
  background-position: center;
  grid-column: 3/7;
  grid-row: 22/35;
  cursor: pointer;
}

.grid-item9 {
  background-image: url('../../../../public/Images/GridImages/image8.png');
  background-size: cover;
  background-position: center;
  grid-column: 7/9;
  grid-row: 18/28;
  cursor: pointer;
}

.grid-item10 {
  background-image: url('../../../../public/Images/GridImages/image9.png');
  background-size: cover;
  background-position: center;
  grid-column: 9/11;
  grid-row: 18/35;
  cursor: pointer;
}

.grid-item11 {
  background-image: url('../../../../public/Images/GridImages/image11.png');
  background-size: cover;
  background-position: center;
  grid-column: 1/3;
  grid-row: 31/35;
  cursor: pointer;
}

.grid-item12 {
  background-image: url('../../../../public/Images/GridImages/image12.png');
  background-size: cover;
  background-position: center;
  grid-column: 7/9;
  grid-row: 28/35;
  cursor: pointer;
}

.ViewMore {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.btn.ViewMoreButton {
  width: 162px;
  height: 50px;
  border-radius: 40px;
  gap: 8px;
  background-color: #4a90e2;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
