.LoginScreen {
  position: relative;
  overflow-y: hidden;
  height: 100vh;
}

.alert-danger {
  height: 3rem;
  margin-left: 20%;
  width: 65%;
}
.yellowparticles {
  width: 35%;
  position: absolute;
  top: 120px;
  left: -8%;
  transform: rotate(-56deg);
  z-index: -1;
}
.dbparticles {
  width: 30%;
  position: absolute;
  left: 55%;
  top: -20px;
  z-index: -1;
  transform: rotate(-56deg);
}
.lbparticles {
  width: 30%;
  position: absolute;
  left: 60%;
  bottom: -20%;
  z-index: -1;
  transform: rotate(-56deg);
}
.LoginWindow {
  height: 70%;
  width: 60%;
  margin-top: 7%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  box-shadow: 2px 4px 4px 0px #00000040, -1px 0px 4px 0px #00000040;
  border: 0px solid black;
  border-radius: 8px;
}
.LoginWindow h1 {
  padding-top: 3%;
  font-size: 24px;
  font-weight: 510;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.LoginWindow p {
  padding-top: 1%;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
}
.LoginWindow p span {
  color: #4a90e2;
  font-weight: 600;
}
.LoginForm {
  position: relative;
  margin-left: 20%;
  margin-bottom: 7%;
}
.LoginForm label {
  margin-top: 2%;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.LoginForm input {
  border: 0.5px solid black;
  border-radius: 4px;
  width: 80%;
  height: 40px;
  padding: 10px;
}

.LoginForm input::placeholder {
  padding-left: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: grey;
}
.LoginForgotPassword.btn {
  margin-top: 2%;
  margin-right: 20%;
  padding: 0;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  float: right;
}
.LoginLogin.btn {
  text-align: center;
  position: absolute;
  margin-top: 2%;
  left: 30%;
  background-color: #002f4d;
  width: 100px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  border-radius: 4px;
  gap: 10px;
}
.LoginLine {
  height: 0px;
  border: 0.5px solid grey;
  width: 60%;
  margin-left: 20%;
  margin-top: 10%;
  margin-bottom: 5%;
}
.LoginButtons {
  margin-left: 20%;
}
.LoginGoogle.btn {
  width: 260px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 33px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.LoginGoogle span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
}
.LoginLinkedIn.btn {
  margin-left: 5%;
  width: 260px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 33px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.LoginLinkedIn span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
}
@media screen and (max-width: 480px) {
  .LoginLine {
    height: 0px;
    border: 0.5px solid grey;
    width: 60%;
    margin-left: 20%;
    margin-top: 40%;
    margin-bottom: 5%;
  }
  .LoginLogin.btn {
    text-align: center;
    position: absolute;
    margin-top: 8%;
    left: 15%;
    background-color: #002f4d;
    width: 100px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    border-radius: 4px;
    gap: 10px;
  }
  .LoginButtons {
    margin-left: 10%;
  }
  .LoginGoogle.btn {
    width: 200px;
    height: 40px;
    border: 1px solid grey;
    border-radius: 33px;
    margin-top: 10%;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }

  .LoginLinkedIn.btn {
    margin-left: 0px;
    width: 200px;
    height: 40px;
    border: 1px solid grey;
    border-radius: 33px;
    margin-top: 10%;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }

  i {
    font-size: 200px !important;
  }
}
