.ProjectsLanding{
    padding-top: 15%;
}
.LandingContent{
    text-align: center;
}
.LandingHeading{   
    font-size: 52px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0.02em;
    color:#000000;
}
.LandingInfo{
    padding-top: 1%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #000000;

}
.btn.LandingButton {
    width: 112px;
    height: 41px;
    border-radius: 4px;
    border: 2px solid  #4A90E2;
    color: #4A90E2;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
}
.fa-solid.fa-arrow-right-from-bracket{
    color:#4A90E2;
}