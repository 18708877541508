.ProjectDetailsIntro{
    padding-top: 2%;
    padding-left: 5%;
    display: flex;
    margin-top: 8rem;
    text-align: left;
}
.ProjectDetailsIntroText{
  
}
.ProjectDetailsIntroText h1{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    padding-bottom: 3%;
}
.ProjectDetailsIntroText p{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: grey;
}
.ProjectDetailsIntroImage{
    text-align:right;
    width: 48%;
}
.btn.ProjectsDetailsIntrobtn1{
    width: 125px;
    height: 42px;
    border: solid 2px;
    border-radius: 20px;
    border-color: #4A90E2;
    color: #4A90E2;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: 20px;
}
.ProjectsDetailsIntrobtn2{
    width: 150px;
    margin-top: 10px;
    border: none;
    background-color: transparent;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
}
.fa-globe{
    color: black;
}
.fa-arrow-right{
    color:black;
}
.ProjectDetailsVideo{
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 3%;
}
.ProjectDetailsTech{
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;
}
.ProjectDetailsTech h2{
    margin-left: 4%;
    width: 124px;
    height: 45px;
    border-radius: 50px;
    background-color: #002F4D;
    color: white;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    text-align: center;
    padding-top: 0.5%;
}
.ProjectDetailsInfo{
    display: flex;
    min-height: 300px;
}
.ProjectDetailsInfoChallenges{
    box-shadow: 2px 2px 4px 0px #00000026;
    text-align: center;
    margin-left: 4%;
    min-width: 45%;
    border: 1px solid #808080
}
.ProjectDetailsInfo h1{
    margin-top: 4%;
    font-size: 24px;
    font-weight: 520;
    line-height: 30px;
    letter-spacing: 0em;

}
.ProjectDetailsInfo p{
    margin-top: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #808080;

}
.ProjectDetailsInfoSolutions{
    box-shadow: 2px 2px 4px 0px #00000026;
    text-align: center;
    margin-left: 2%;
    min-width: 45%;
    border: 1px solid #808080
}
.ProjectDetailsFeedback{
    margin-left: 4%;
    margin-top: 4%;
}
.ProjectDetailsFeedback h1{
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}
.ProjectDetailsFeedback p{
    margin-top: 2%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #808080;
}
.ProjectDetailsLineBreaker{
    display: flex;
}
.ProjectDetailsLine{
    height: 0;
    margin-left: 4%;
    margin-top: 0.75%;
    width: 45%;
    border: 1px solid #808080;
    display: inline-block;
}
.ProjectDetailsLinee{
    height: 0;
    margin-right: 4%;
    margin-top: 0.75%;
    width: 45%;
    border: 1px solid #808080;
    display: inline-block;
}
.fa-chevron-down{
    margin-left: 2%;
    margin-right: 2%;
    border: 2.12px solid grey;
    border-radius: 50%;
    padding: 2px;
    color: grey;
}
.ProjectDetailsMore{
    margin-left: 4%;
}
.ProjectDetailsMore h1{
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}
.ProjectDetailsMoreImages{
    display: flex;
}
.ProjectDetailsMoreImage{
    box-shadow: 0px 2.7164547443389893px 2.7164547443389893px 0px #00000040;
    width: 30%;
    margin-right: 3%;
    margin-bottom: 3%;
}