.nav {
  margin-bottom: 1rem;
}

.nav-item {
  width: 23%;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.achievementstitle{
  text-align: center;
  margin-bottom: 6rem;
}
.achievementstitle1{

opacity: 0.1;
font-size: 64px;
font-weight: 800;
line-height: 75px;
letter-spacing: 0.02em;
color: linear-gradient(180deg, #002F4D 50.74%, rgba(0, 47, 77, 0) 100%);

}
.achievementstitle2{
  margin-top: -2.5rem;
  font-size: 32px;
  font-weight: 700;
  line-height: 40.22px;
  letter-spacing: 0.0125em;
  color: #040404;
}
.highlight {
  color: #4A90E2;
  font-size: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.14px;
  border-bottom: 3px solid #4A90E2;
}

.projects {
  margin: 2rem 3rem;
  opacity: 1;
}

.carousel {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.react-multi-carousel-list {
  position: unset !important;
}

.btn.achievementsviewmorebutton{
  width:  163px;
  height: 44pxpx;
  gap: 8px;
  border-radius: 40px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.65px;
  background-color: #4A90E2;
  margin-top: 2rem;
}