* {
  margin: 0;
  padding: 0;
}
.teamtitle{
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.22px;
  color: #002F4D;
}
.team {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.cards {
  display: flex;
  width: 80vw;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 28rem;
  flex: 0.5;
  position: relative;
  margin: 0;
  transition: flex 0.4s;
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.card h2 {
  color: white;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
  bottom: 3rem;
  left: 1.3rem;
  text-align: center;
}
.card p {
  color: rgb(255, 255, 255);
  position: absolute;
  opacity: 0;
  transition: opacity 0.8s;
  bottom: 1rem;
  text-align: center;
  left: 1.3rem;
  font-weight: bolder;
  font-size: 1rem;
}

.card.active {
  flex: 1.7;
  opacity: 1;
  margin: 0.5rem;
}

.card.active h2 {
  opacity: 1;
}

.card.active p {
  opacity: 1;
}

.fas.circleHover {
  font-size: 1.4rem;
  margin: 10px;
}

.circleHover:hover {
  color: grey;
}
